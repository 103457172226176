import "lightbox-react/style.css"
import "./src/scss/index.scss"

// embed sproutstudio popup script
export const onClientEntry = () => {
  const script = document.createElement("script")
  script.src =
    "https://sproutstudio.com/sprout_email_campaign_embed.php?email_campaign_list_id=10755&embedable_id=8760"
  script.id = "sprout-studio-email-campaign-script-8760"
  script.async = true
  document.body.appendChild(script)
}
